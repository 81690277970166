.enough {
  background: #D7E0E4;
  padding-bottom: 70px;

  &__lines {
    &--line {
      background: linear-gradient(90deg, #BBE2EE 0%, #FFFFFF 91.22%);
      box-sizing: border-box;
      box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.8), 10px 8px 10px rgba(5, 5, 5, 0.05);
      border-radius: 500px;
      margin-bottom: 24px;

      @media only screen and (max-width: 600px) {
        display: block;
        text-align: center;
      }

      &:nth-child(1) {
        @media only screen and (min-width: 992px) {
          width: 50%;
        }
      }

      &:nth-child(2) {
        @media only screen and (min-width: 992px) {
          width: 60%;
        }
      }

      &:nth-child(3) {
        @media only screen and (min-width: 992px) {
          width: 75%;
        }
      }

      &:last-child {
        @media only screen and (min-width: 992px) {
          width: 90%;
        }
      }

      .lineborder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 500px;
        padding: 10px 20px 10px 30px;
        border: 8px solid rgba(255, 255, 255, 0.2);

        @media only screen and (max-width: 440px) {
          display: block;
        }

        p, h3, h4 {
          margin: 0;
          font-size: 1rem;
        }

        div {
          & + div {
            color: $light-blue;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: 440px) {
              margin-top: 12px;
            }

            h3 {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  p.tip {
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #707C82;
    margin: 25px 0;
  }

  .btn {
    display: block;
    margin: 0 auto;
    width: auto;
    font-size: 18px;
    line-height: 22px;
    padding: 15px 52px 15px 62px;
    border-radius: 50px;
    box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.8), 10px 8px 10px rgba(5, 5, 5, 0.05);

    @media only screen and (max-width: 600px) {
      display: block;
      margin: 0 auto;
      width: 90%;
      padding: 15px 25px;
    }
  }

  .more-option-container {
    margin-top: 40px;

    .bottom-info {
      p {
        text-align: center;
      }

      .btn {
        display: flex;
        width: 350px;
      }
    }
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    &__more {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 40px 35px 30px;
      margin: 0 0 4rem;
      border-bottom: 11px solid #3CB0E6;
      width: 47%;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 25px;
        width: 100%;
      }

      h4 {
        font-size: 20px;
        color: #3CB0E6;
      }
    }
  }
}
