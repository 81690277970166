.prices {
  background: #D7E0E4;

  .col-xl-4 {
    @media only screen and (max-width: 1390px) {
      margin-bottom: 2rem;
    }
  }

  &__box {
    background: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px 45px 55px;
    text-align: center;
    margin: 0 auto;

    @media only screen and (max-width: 1390px) {
      padding: 40px 35px 55px;
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      padding: 20px;
    }

    &--img {
      width: 70%;
    }

    .price-main {
      margin-top: 32px;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-weight: 600;
        font-size: 35px;
        line-height: 52px;
        margin: 0;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .price-list {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      ul {
        padding: 0;

        li {
          text-align: left;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #6A7C89;
          padding-bottom: 25px;
          position: relative;
          padding-left: 35px;

          &:before {
            content: url('../../images/pipe.svg');
            position: absolute;
            top: 0;
            left: 0;
          }

          &.cancel {
            &:before {
              content: url('../../images/cancel.svg');
              position: absolute;
              top: 0;
              left: 0;
              filter: invert(78%) sepia(3%) saturate(2%) hue-rotate(342deg) brightness(101%) contrast(85%);
            }
          }
        }
      }

      .btn {
        width: 213px;
        height: 66px;
        border-radius: 30px;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
      }
    }

    &.free {
      .price-main {
        h3 {
          font-size: 24px;
        }
      }

      .price-list {
        .null {
          &:before {
            content: '0';
            color: $light-blue;
            font-weight: 500;
            font-size: 22px;
            text-decoration: line-through;
          }
        }
      }
    }

    &.word,
    &.cloud {
      border-bottom: 11px solid $light-blue;
      height: 100%;

      .price-main {
        h3, span {
          color: $light-blue;
          display: inline-block;
        }
      }

      .btn {
        background: $light-blue;
      }
    }
  }
}
