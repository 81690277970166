.advantages {
  background: transparent url('../../images/advantages-bg.png') no-repeat center;
  background-size: cover;
  padding-bottom: 90px;

  @media only screen and (max-width: 600px) {
    padding-top: 6rem;
  }

  .advantage-img {
    display: block;
    width: 100%;
  }

  .title {
    h3 {
      color: $white;
    }
  }

  .spin-container {
    position: relative;
    pointer-events: none;

    img {
      width: 85%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__spinner {
    position: absolute;
    top: 40%;
    left: 12.5%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 400px;
    height: 150px;
    overflow: hidden;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.15);
    pointer-events: none;

    @media only screen and (max-width: 1390px) {
      left: 8%;
    }

    @media only screen and (max-width: 1200px) {
      left: 8%;
      top: 20%;
      width: 350px;
    }

    @media only screen and (max-width: 991px) {
      top: 40%;
      left: 16%;
    }

    @media only screen and (max-width: 440px) {
      width: 75%;
      height: auto;
      left: 8%;
    }

    &--left {
      background: $white;
      width: 130px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      @media only screen and (max-width: 1200px) {
        width: 30%;
      }

      @media only screen and (max-width: 440px) {
        width: 20%;
      }

      h2 {
        font-weight: bold;
        font-size: 70px;
        line-height: 105px;
        color: $dark;
        margin: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 62px;
        right: -12px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 15px solid $white;
        border-bottom: 15px solid transparent;
      }
    }

    &--right {
      background: #3CB0E6;
      color: $white;
      width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 26px;
      pointer-events: none;

      @media only screen and (max-width: 1200px) {
        width: 70%;
      }

      @media only screen and (max-width: 440px) {
        width: 80%;
      }

      div {
        width: 100%;

        &.big {
          margin-top: 0px;

          p {
            font-size: 20px;

            @media only screen and (max-width: 1200px) {
              margin-top: -25px;
            }

            @media only screen and (max-width: 440px) {
              font-size: 16px;
            }
          }
        }

        p {
          font-weight: 500;
          font-size: 28px;
          line-height: 130%;
          color: $white;
          margin: 0;
          margin-top: -10px;

          @media only screen and (max-width: 1200px) {
            font-size: 25px;
          }

          @media only screen and (max-width: 440px) {
            font-size: 22px;
          }
        }
      }

      .slick-slide {
        padding: 20px 0;
        height: 96px;
        opacity: 0;
        transition: all 1.75s ease;
      }

      .slick-slide.slick-current {
        opacity: 1;
      }
    }
  }

  &__box {
    background: rgb(37, 69, 91);
    border-radius: 15px;
    padding: 20px 25px;
    margin-bottom: 30px;
    width: 480px;

    @media only screen and (max-width: 1366px) {
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 1199px) {
      width: 100%;
    }

    @media only screen and (max-width: 991px) {
      margin: 0 auto;
    }

    &--title {
      display: flex;

      img {
        margin-right: 13px;
      }

      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: $white;
      }
    }

    &--content {
      ul {
        padding-left: 20px;
        margin-top: 20px;

        li {
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #9CB7C3;
          position: relative;
          margin-bottom: 12px;

          @media only screen and (max-width: 1366px) {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 8px;
          }

          &:before {
            content: url('../../images/arrow-list.svg');
            position: absolute;
            top: 3px;
            left: -20px;
          }
        }
      }
    }
  }
}
