// Ubuntu
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
$ubuntu: 'Ubuntu', sans-serif;

h1, h2, h3, h4, h5, h6, p, a, li {
  font-family: $ubuntu;
}

h3 {
  font-size: 31px;
  line-heigh: 46px;
  font-weight: 800;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
}

h4 {
  font-size: 24px;
  line-heigh: 36px;
  font-weight: 600;
}
