.register {
  padding-top: 35px;
}

.login,
.register,
.forgot {
  background: #C8D6DC;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    text-align: center;
    width: 450px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    &__logo {
      margin: 0 auto 2rem;

      img {
        width: 65%;
        display: block;
        margin: 0 auto;
      }
    }

    &__row {
      margin-bottom: 1.15rem;

      .ui.input {
        width: 100%;
      }

      input {
        border: 0;
        border-radius: 6px;
        padding: 12px 18px;
        font-size: 1.15rem;
        width: 100%;
        display: block;

        &:focus {
          outline: none;
        }
      }

      .error {
        color: red;
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: left;
      }

      button {
        font-size: 1.25rem;
        border-radius: 999px;
      }

      &.check {
        .custom-control {
          position: relative;
          z-index: 1;
          display: block;
          min-height: 1.5rem;
          padding-left: 1.5rem;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;

          .custom-control-input {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;

            &:checked ~ .custom-control-label:before {
              color: #212529;
              border-color: $light-blue;
              background-color: $light-blue;
            }

            &:not(:disabled):active ~ .custom-control-label:before {
              color: #212529;
              background-color: $light-blue;
              border-color: $light-blue;
            }
          }

          .custom-control-label {
            position: relative;
            margin-bottom: 0;
            color: #000;
            vertical-align: top;
            cursor: pointer;

            &:before {
              pointer-events: none;
              background-color: transparent;
              border: 1px solid #000;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            }

            &:after {
              background: no-repeat 50%/50% 50%;
            }

            &:before,
            &:after {
              position: absolute;
              top: .75rem;
              left: -1.5rem;
              display: block;
              width: 1.5rem;
              height: 1.5rem;
              content: "";
            }
          }
        }

        .custom-checkbox {
          .custom-control-label:before {
            border-radius: .25rem;
          }

          .custom-control-input:checked ~ .custom-control-label:after {
            content: '\f00c';
            font-family: 'fontawesome';
            margin-top: -1px;
            font-size: 18px;
          }
        }
      }
    }

    a {
      text-decoration: none;
      color: $light-blue;
    }
  }
}
