.form {

  &__row {
    margin-bottom: 1.15rem;

    .ui.input {
      width: 100%;
    }

    input, textarea {
      border: 1px solid rgba(176, 186, 190, 0.5);
      border-radius: 6px;
      padding: 10px 16px;
      font-size: 1rem;
      width: 100%;
      display: block;
      margin-bottom: 1.15rem;

      &:focus {
        outline: none;
      }
    }

    .error {
      color: red;
      margin-top: 10px;
      display: block;
      width: 100%;
      text-align: left;
    }

    button {
      font-size: 1.25rem;
      border-radius: 999px;
    }
  }
}
