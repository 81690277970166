.demo {
  &__title {
    text-align: center;
    margin-bottom: 4rem;
  }

  &__error {
    text-align: center;
    margin-bottom: 1rem;
    color: red;
  }

  &__item {
    margin-bottom: 3rem;
    text-align: center;

    p {
      color: $text-blue;
    }

    img {
      width: 50%;
      min-width: 18rem;
    }

    button {
      border: 0px;
      background-color: transparent;
    }
  }
}
