.cart {
  padding-top: 2rem;

  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    overflow-x: scroll;
    height: calc(100vh - 9.65rem);

    @media screen and (max-width: 720px) {
      display: block;
    }
  }

  .success {
    padding: 2rem 0 3rem;
    div {
      display: flex;
      align-items: flex-start;
      font-size: 1.15rem;
    }

    .btn {
      border-radius: 999px;
    }

    .btn-container {
      text-align: center;

      .btn {
        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }

  .error {
    color: red;
    margin: 10px 0 20px;
    display: block;
    width: 100%;
    text-align: left;
  }

  .bs-stepper {
    .bs-stepper-header {
      .step {
        min-width: 240px;
        text-align: center;
      }
    }

    .cart-table {
      td {
        &:last-child {
          text-align: right;
        }
      }

      tr.summary td {
        border-top: 2px black solid;
        border-bottom: 0px;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .table {
      .summary td {
        border-top: 2px black solid;
        border-bottom: 0px;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .billing {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        border: 1px solid rgba(176, 186, 190, 0.5);
        border-radius: 6px;
        padding: 12px 18px;
        font-size: 1.15rem;
        width: 100%;
        max-width: 30rem;
        display: block;
        margin-bottom: 1.15rem;

        &:focus {
          outline: none;
        }
      }
    }

    .finalization {
      padding: 2rem 0 3rem;

      div {
        display: flex;
        align-items: flex-start;

        h5 {
          margin-right: 15px;
        }
      }
    }

    .success {
      padding: 2rem 0 3rem;

      div {
        display: flex;
        align-items: flex-start;

        h5 {
          margin-right: 15px;
        }
      }
    }

    .btn {
      border-radius: 999px;
    }

    .btn-container {
      text-align: center;

      .btn {
        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
