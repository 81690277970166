.dashboard {
  padding: 0;

  .btn {
    color: #fff;
    border-radius: 999px;
    display: inline-block;
  }

  .uploader {
    p {
      margin: 0;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    height: calc(100vh - 8.65rem);

    @media screen and (max-width: 720px) {
      display: block;
    }

    .nav {
      height: 100%;
      width: 15%;
      min-width: 16rem;
      max-width: 20rem;
      background: #D7E0E4;
      padding: 2rem 1rem 0 0;
      display: block;

      @media screen and (max-width: 720px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        padding: 2rem 0 1rem;
      }

      .nav-item {
        text-decoration: none;
        width: 100%;
        display: block;

        &:nth-child(1),
        &:nth-child(4) {
          .nav-link {
            color: grey;
            border-bottom: 1px solid grey;

            @media screen and (max-width: 720px) {
              display: none;
            }
          }
        }

        &:nth-child(4) {
          .nav-link {
            margin-top: 1rem;
          }
        }

        .nav-link {
          background: transparent;
          color: $light-blue;
          border: 0;
          width: 100%;
          text-align: left;

          &.active {
            background-color: transparent;
            border: 0;
            color: $blue;
          }
        }

        @media screen and (max-width: 720px) {
          display: inline-block;
          width: auto;
        }
      }
    }

    .tab-content {
      width: 88%;
      padding: 3rem 2rem 2rem;
      overflow-x: scroll;

      @media screen and (max-width: 720px) {
        width: 100%;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__inner {
    &--row {
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid rgba(176, 186, 190, 0.5);

      &:last-child {
        border-bottom: 0;
      }

      &.subscription {
        & > img {
          margin-bottom: 2rem;
        }

        .btn {
          width: 18rem;
        }
      }

      .error {
        color: red;
        margin: 10px 0 20px;
        display: block;
        width: 100%;
        text-align: left;
      }

      .table {
        tr {
          td {
            &:last-child {
              text-align: right;
            }
          }
        }

        .btn {
          i {
            margin-right: 6px;
          }
        }

        &.order-table {
          .btn {
            width: 12rem;
          }
        }
      }

      & > div {
        & + div {
          margin-top: 1rem;
        }
      }

      h2 {
        font-size: 1.75rem;
        font-weight: bold;
      }

      h3 {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      h4 {
        font-size: 1rem;
      }

      p {
        margin: 0 0 12px;
      }

      a {
        margin: 0 0 12px;
        text-decoration: none;
        display: block;
      }

      input:not([type='radio']) {
        border: 1px solid rgba(176, 186, 190, 0.5);
        border-radius: 6px;
        padding: 10px 16px;
        font-size: 1rem;
        width: 100%;
        max-width: 20rem;
        display: block;
        margin-bottom: 1.15rem;

        &:focus {
          outline: none;
        }
      }

      input.with-label {
        display: inline-block;
        margin-right: 1.15rem;
      }

      .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 2.0rem;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;

        .custom-control-input {
          position: absolute;
          left: 0;
          z-index: -1;
          width: 1rem;
          height: 1.25rem;
          opacity: 0;

          &:checked ~ .custom-control-label:before {
            color: #212529;
            border-color: $light-blue;
            background-color: $light-blue;
          }

          &:not(:disabled):active ~ .custom-control-label:before {
            color: #212529;
            background-color: $light-blue;
            border-color: $light-blue;
          }
        }

        .custom-control-label {
          position: relative;
          margin-bottom: 0;
          color: #000;
          vertical-align: top;
          cursor: pointer;

          &:before {
            pointer-events: none;
            background-color: transparent;
            border: 1px solid #000;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          }

          &:after {
            background: no-repeat 50%/50% 50%;
          }

          &:before,
          &:after {
            position: absolute;
            top: 0rem;
            left: -2rem;
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            content: "";
          }
        }
      }

      .custom-checkbox {
        .custom-control-label:before {
          border-radius: .25rem;
        }

        .custom-control-input:checked ~ .custom-control-label:after {
          content: '\f00c';
          font-family: 'fontawesome';
          margin-top: -1px;
          margin-left: 2px;
          font-size: 18px;
        }
      }

      &.docs {
        a {
          display: block;
        }
      }

      .subscription {
        display: flex;
        justify-content: space-between;
        max-width: 45rem;
        padding: 4rem 0;

        &__box {
          text-align: center;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 20px 35px;
          border-bottom: 11px solid #3CB0E6;
        }
      }

      .license {
        display: flex;
        width: 100%;

        @media screen and (max-width: 720px) {
          flex-wrap: wrap;
        }

        h4 {
          margin-bottom: 1.5rem;
        }

        &__inner {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media screen and (max-width: 720px) {
            width: 50%;
          }
        }

        input.apple-switch {
          position: relative;
          -webkit-appearance: none;
          outline: none;
          width: 50px;
          height: 30px;
          background-color: #fff;
          border: 1px solid #D9DADC;
          border-radius: 50px;
          box-shadow: inset -20px 0 0 0 #fff;

          &:after {
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            background: transparent;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
          }

          &:checked {
            box-shadow: inset 20px 0 0 0 $light-blue;
            border-color: $light-blue;

            &:after {
              left: 20px;
              box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
            }
          }
        }
      }

      .demoProd {

        div {
          margin: 0rem 1rem;

          input.apple-switch {
            position: relative;
            -webkit-appearance: none;
            outline: none;
            width: 50px;
            height: 30px;
            background-color: #fff;
            border: 1px solid #D9DADC;
            border-radius: 50px;
            box-shadow: inset -20px 0 0 0 #fff;

            &:after {
              content: "";
              position: absolute;
              top: 1px;
              left: 1px;
              background: transparent;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
            }

            &:checked {
              box-shadow: inset 20px 0 0 0 $light-blue;
              border-color: $light-blue;

              &:after {
                left: 20px;
                box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
              }
            }
          }
        }
      }
    }

    &.helper {
      width: 75%;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  .slider {
    width: 25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .rangeslider {
      flex: 1;
      margin-right: 1rem;

      &__fill {
        background-color: $light-blue;
      }
    }
  }

  .enough {
    &__lines {
      &--line {
        width: 100%;

        .lineborder {
          div {
            & + div {
              display: flex;
              align-items: center;

              h3 {
                margin-right: 10px;
              }
            }
          }

          .btn {
            width: 15rem;
          }
        }
      }
    }
  }
}
