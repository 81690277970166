.guarantee {
  background: #C8D6DC;

  .col-lg-6 {
    @media only screen and (max-width: 1280px) {
      margin-bottom: 40px;
    }
  }

  .question-two {
    .questions {
      height: 100%;

      &__bottom {
        height: 80%;
      }
    }
  }

  h4 {
    text-align: center;
  }

  &__side {
    .bubble {
      background: #E8ECEE;
      border-radius: 10px;
      box-shadow: -8px -6px 20px rgba(255, 255, 255, 0.9), 10px 7px 20px rgba(0, 0, 0, 0.15);
      width: 380px;
      margin: 0 auto;
      padding: 15px 30px;
      position: relative;

      @media only screen and (max-width: 600px) {
        width: 75%;
      }

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #373737;
        margin: 0;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -22px;
        left: 50%;
        width: 0;
        height: 0;
        border-top: 44px solid transparent;
        border-left: 44px solid transparent;
        border-bottom: 44px solid #E8ECEE;
        transform: rotate(45deg);
      }
    }

    .download {
      margin-top: 65px;
      text-align: center;

      ol {
        margin: 25px 0;
        padding: 0 80px 0 130px;
        height: 8rem;

        @media only screen and (max-width: 1200px) {
          height: 12rem;
        }

        @media only screen and (max-width: 992px) {
          height: auto;
        }

        li {
          text-align: left;
          list-style-type: decimal;
        }
      }

      .btn {
        font-size: 18px;
        line-height: 22px;
        width: auto;
        border-radius: 30px;
        margin-bottom: 35px;
      }
    }

    .setup {
      &__text {
        text-align: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: #373737;

        span, a {
          display: block;
          font-weight: 700;
          color: $light-blue;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .easy {
    padding-bottom: 3rem;

    ul {
      width: 1100px;
      margin: 0 auto;
      padding: 0;

      @media only screen and (max-width: 1120px) {
        width: 90%;
      }

      li {
        background: rgb(37, 69, 91);
        border-radius: 15px;
        padding: 20px 25px;
        margin-bottom: 40px;
        color: $white;

        h5 {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: $white;
          display: inline-block;
        }

        i {
          display: inline-block;
          margin-right: 15px;
          font-size: 40px;
          color: #3CB0E6;
        }

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #9CB7C3;
          position: relative;
          margin-bottom: 12px;
          padding-left: 20px;

          @media only screen and (max-width: 1366px) {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 8px;
          }

          &:before {
            content: url('../../images/arrow-list.svg');
            position: absolute;
            top: 3px;
            left: 0px;
          }
        }
      }
    }
  }

  .video {
    margin: 32px auto 18px;
    width: 100%;
    max-width: 991px;

    @media only screen and (max-width: 992px) {
      width: 100%;
      height: 400px;
    }

    @media only screen and (max-width: 768px) {
      height: 320px;
    }

    iframe {
      width: 100%;
      min-height: 35rem;
      height: 100%;
      display: block;
      margin: 0 auto;

      @media only screen and (max-width: 1200px) {
        min-height: 25rem;
      }

      @media only screen and (max-width: 768px) {
        min-height: 20rem;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;

    .btn {
      font-size: 18px;
      line-height: 22px;
      display: inline-block;
      width: auto;
      border-radius: 30px;
      margin: 1rem auto 15px;
    }
  }

  .questions {
    margin-top: 35px;

    &__bottom {
      background: #B6C8D0;
      width: 80%;
      max-width: 991px;
      margin: 0 auto 40px;
      border-radius: 10px;
      padding: 26px 30px;

      @media only screen and (max-width: 992px) {
        width: 100%;
      }

      @media only screen and (max-width: 440px) {
        padding: 20px;
      }

      btn.link {
        display: block;
        text-align: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #39AADF;
        padding: 5px 0;
        transition: all .25s ease;
        cursor: pointer;

        img {
          transition: all .25s ease;
        }

        &:hover {
          color: $blue;

          img {
            transform: translateX(10px);
            transition: all .25s ease;
          }
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #373737;
        margin: 16px 0;
      }

      .btn {
        border-radius: 30px;
        margin: 0 auto;
        display: block;
        width: auto;
        font-size: 18px;
        line-height: 22px;

        @media only screen and (max-width: 440px) {
          width: 90%;
        }
      }
    }
  }
}
