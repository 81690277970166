footer {
  background: #13253E;
  color: $white;
  padding: 20px 0;

  .footer-links {
    @media only screen and (max-width: 992px) {
      text-align: center;
    }

    a {
      text-decoration: none;
      color: $white;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      &:after {
        content: '|';
        margin: 0 15px;
        opacity: .2;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .footer-text {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 992px) {
      text-align: center;
      justify-content: center;
      padding-top: 1.5rem;
    }

    p {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: right;

      span {
        display: block;
        font-size: 11px;

        a {
          color: $light-blue;
          text-decoration: none;
        }
      }
    }
  }

  .cookie-consent {
    align-items: baseline;
    background: #13253E;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 999;

    a {
      color: $light-blue;
      text-decoration: none;
    }
  }

  .cookie-consent-content {
    flex: 1 0 300px;
    margin: 15px;
  }

  .cookie-consent-button {
    flex: 0 0 auto;
    margin: 15px;
  }
}
