.hero {
  background: rgb(236, 239, 241);
  padding: 67px 0 120px;
  position: relative;

  @media only screen and (max-width: 991px) {
    background-image: none;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .logo {
    display: block;
    width: 498px;
    margin-bottom: 85px;
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 600px) {
      width: 85%;
      margin: 0 auto 40px;
    }
  }

  &__text {
    color: $white;
    position: relative;
    z-index: 10;

    h3 {
      text-transform: uppercase;
      color: #1F3B4E;
    }

    h4 {
      color: #1F3B4E;
    }

    ul {
      padding-top: 28px;
      padding-bottom: 38px;
      margin: 0;

      li {
        color: #4C5860;
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 24px;
        position: relative;

        &:before {
          content: url('../../images/pipe.svg');
          position: absolute;
          top: 3px;
          left: -35px;
        }
      }
    }

    .btn {
      font-size: 18px;
      line-height: 22px;
      padding: 15px 52px 15px 62px;
      border-radius: 50px;
      box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.8), 10px 8px 10px rgba(5, 5, 5, 0.05);

      @media only screen and (max-width: 600px) {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .hand {
    display: block;
    width: 100%;
  }

  .scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    position: relative;
    bottom: -5rem;

    .scroll-downs {
      margin: 0 auto;
      display: block;
      width: 37px;
      height: 55px;
      opacity: 0.65;
      transition: all .25s ease;

      &:hover {
        transform: scale(1.05);
        transition: all .25s ease;
        opacity: 1;
      }

      .mousey {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-radius: 25px;
        box-sizing: content-box;
      }

      .scroller {
        width: 3px;
        height: 10px;
        border-radius: 25%;
        background-color: rgba(0, 0, 0, 0.5);
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(.15,.41,.69,.94);
        animation-iteration-count: infinite;
      }
    }

    @keyframes scroll {
      0% { opacity: 0; }
      10% { transform: translateY(0); opacity: 1; }
      100% { transform: translateY(15px); opacity: 0;}
    }
  }
}
