// Reset
@import 'reset.css';

// Bootatrap
@import '~bootstrap/scss/bootstrap';

// Slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// Variables
@import 'styles/variables/variables--colors';
@import 'styles/variables/variables--fonts';

// Elements
@import 'styles/elements/elements--buttons';

// Components
@import 'styles/components/components--all';
@import 'styles/components/components--header';
@import 'styles/components/components--footer';
@import 'styles/components/components--main';
@import 'styles/components/components--title';
@import 'styles/components/components--modal';

// Sections
@import 'styles/sections/sections--about';
@import 'styles/sections/sections--advantages';
@import 'styles/sections/sections--all';
@import 'styles/sections/sections--enough';
@import 'styles/sections/sections--faq';
@import 'styles/sections/sections--guarantee';
@import 'styles/sections/sections--hero';
@import 'styles/sections/sections--prices';

// Pages
@import 'styles/pages/pages--userform';
@import 'styles/pages/pages--dashboard';
@import 'styles/pages/pages--demo';
@import 'styles/pages/pages--cart';
