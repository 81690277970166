.title {
  margin-bottom: 32px;

  h3 {
    font-weight: 800;
    font-size: 37px;
    line-height: 55px;
    text-transform: uppercase;
    color: $dark;

    @media only screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    }
  }
}
